<template>
  <nav
    id="topbar"
    class="navbar navbar-expand-lg fixed-top navbar-homepage"
  >
    <div class="container-fluid px-lg-5">
      <!--      <a class="navbar-brand" href="/"      >-->
      <router-link
        class="navbar-brand"
        :to="{ name: 'Homepage' }"
      >
        <img
          src="/img/logo-color.png"
          alt="Trepr"
          title="Trepr"
          width="150"
          height="49"
        >
      </router-link>
      <button
        class="navbar-toggler bg-secondary"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarToggler"
        aria-controls="navbarToggler"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"><i class="fas fa-bars text-light" /></span>
      </button>
      <div
        id="navbarToggler"
        class="collapse navbar-collapse"
      >
        <ul class="navbar-nav navbar-top me-auto ms-auto mb-0 navbar-center">
          <li class="nav-item">
            <a
              :href="
                $router.resolve({
                  name: 'ServiceRateCalculations',
                  params: { serviceType: 'people' },
                }).href
              "
              class="nav-link"
            >
              Air Companionship Rates
            </a>
          </li>
          <li class="nav-item">
            <a
              :href="
                $router.resolve({
                  name: 'Faq',
                }).href
              "
              class="nav-link"
            >
              FAQ
            </a>
          </li>
          <li class="nav-item dropdown">
            <a
              id="navbarDropdown"
              class="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Tools
            </a>
            <ul
              class="dropdown-menu"
              aria-labelledby="navbarDropdown"
            >
              <li>
                <a
                  :href="
                    $router.resolve({
                      name: 'OverseasOnlineMerchants',
                    }).href
                  "
                  class="dropdown-item"
                >
                  Overseas Online Merchants
                </a>
              </li>
              <li>
                <a
                  :href="
                    $router.resolve({
                      name: 'BlogList',
                    }).href
                  "
                  class="dropdown-item"
                >
                  Blogs &amp; Publications
                </a>
              </li>
              <li>
                <a
                  :href="
                    $router.resolve({
                      name: 'CustomsExciseDuty',
                    }).href
                  "
                  class="dropdown-item"
                >
                  Customs & Excise Duty
                </a>
              </li>
              <li>
                <a
                  :href="
                    $router.resolve({
                      name: 'Prohibit',
                    }).href
                  "
                  class="dropdown-item"
                >
                  Prohibited or Restricted Items
                </a>
              </li>
              <li>
                <a
                  :href="
                    $router.resolve({
                      name: 'ContactUs',
                    }).href
                  "
                  class="dropdown-item"
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </li>

          <!--          <li class="nav-item" v-if="null == state.token">-->
          <!--            <a-->
          <!--              :href="-->
          <!--                $router.resolve({-->
          <!--                  name: 'Login',-->
          <!--                }).href-->
          <!--              "-->
          <!--              class="nav-link"-->
          <!--            >-->
          <!--              Login-->
          <!--            </a>-->
          <!--          </li>-->
          <!--          <li class="nav-item" v-if="null == state.token">-->
          <!--            <a-->
          <!--              :href="-->
          <!--                $router.resolve({-->
          <!--                  name: 'SignUp',-->
          <!--                }).href-->
          <!--              "-->
          <!--              class="nav-link"-->
          <!--            >-->
          <!--              Sign up-->
          <!--            </a>-->
          <!--          </li>-->

          <LiLoggedin v-if="null != state.token" />
          <!--          <li class="nav-item nav-item-currency bg-secondary">-->
          <!--            <a-->
          <!--              href="/app"-->
          <!--              class="nav-link"-->
          <!--            > Login/Signup </a>-->
          <!--          </li>-->
        </ul>

        <ul class="navbar-nav navbar-right">
          <li :class="{'nav-item me-1 bg-secondary': true}">
            <a
              :href="
                $router.resolve({
                  name: 'BecomeATraveller',
                }).href
              "
              class="nav-link"
            >
              <!-- <img src="/public/images/trav_03.png" alt="trav" /> -->
              <span
                id="become_1"
                :class="{'animate__animated animate__bounceIn animate__slow text-white' : $data.becomeTravellerCount == 1, 'd-none' : $data.becomeTravellerCount != 1}"
              >Become a Traveller</span>
              <span
                id="become_2"
                :class="{'animate__animated animate__rollIn animate__slow text-primary' : $data.becomeTravellerCount == 2, 'd-none' : $data.becomeTravellerCount != 2}"
              >Earn as a Companion</span>
              <span
                id="become_3"
                :class="{'animate__animated animate__bounceIn animate__slow text-white' : $data.becomeTravellerCount == 3, 'd-none' : $data.becomeTravellerCount != 3}"
              >Earn as a Courier</span>
              <span
                id="become_4"
                :class="{'animate__animated animate__rollIn animate__slow  text-primary' : $data.becomeTravellerCount == 4, 'd-none' : $data.becomeTravellerCount != 4}"
              >Earn as a Concierge</span>
            </a>
          </li>
          <li
            v-if="'partner' != state.role"
            class="nav-item dropdown bg-primary"
          >
            <a
              id="partnerHomepage"
              class="nav-link dropdown-toggle text-secondary"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span class=" text-white">Partners</span>
            </a>
            <ul
              class="dropdown-menu"
              aria-labelledby="partnerHomepage"
            >
              <li>
                <a
                  :href="
                    $router.resolve({
                      name: 'PartnerLogin',
                    }).href
                  "
                  class="dropdown-item"
                >Login</a>
              </li>
              <li>
                <a
                  class="dropdown-item"
                  :href="
                    $router.resolve({
                      name: 'PartnerSigunupPersonal',
                    }).href
                  "
                >Signup</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { defineAsyncComponent } from 'vue';
// import LiLoggedin from './bar/LiLoggedin';
const LiLoggedin = defineAsyncComponent(() => import('./bar/LiLoggedin.vue'));
// import SelectCurrency from './SelectCurrency.vue';
import { mapGetters } from 'vuex';

function increaseBecomeTravellerCount(self) {
  var self2 = self;
  setInterval(function() {
    // this.basketAddSuccess = false
    self2.$data.becomeTravellerCount++;
    if (self2.$data.becomeTravellerCount > 4) self2.$data.becomeTravellerCount = 1;
    // console.log("becomeTravellerCount",self2.$data.becomeTravellerCount);
  }, 500);
}
export default {
  name: 'Header',
  components: { LiLoggedin },
  data: function () {
    return {
      becomeTravellerCount: 1,
    };
  },
  computed: {
    ...mapGetters({
      state: 'getState',
    }),
  },

  created() {
    if (this.$store.stage != undefined && this.$store.stage.currencyCode != undefined) {
      let currency = document.getElementById('selectCurrency');
      let currencyCode = currency.value;
      var currencySymbol = currency.options[currency.selectedIndex].getAttribute('data-symbol');
      let obj = {
        currencyCode: currencyCode,
        currencySymbol: currencySymbol,
      };
      this.$store.commit('changeCurrency', obj);

    }
  },
  mounted() {
    setInterval(() => {
      // this.basketAddSuccess = false
      this.$data.becomeTravellerCount++;
      if (this.$data.becomeTravellerCount > 4) this.$data.becomeTravellerCount = 1;
    }, 4000);
  }



};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/app_home.scss';
@import '../../../node_modules/animate.css/animate.min.css';
</style>
